<template>
  <section v-if="form">
    <label for="styleSelector" v-if="label">
      {{ $t(label) }}
      <ToolTip :title="$t(hint)" />
    </label>
    <div class="form-inline">
      <div class="form-group form-group-sm">
        <ColorPicker
          id="editor-border-color"
          class="color-picker"
          v-model="form.border.color"
          icon="fa fa-square-o"
        />
        <select
          name="borderStyle"
          class="form-control medium"
          v-model="form.border.style"
          id="styleSelector"
        >
          <option
            v-for="borderStyle in borderStyles"
            :key="borderStyle"
            :value="borderStyle"
          >
            {{ $t(`synoptic.${borderStyle}`) }}
          </option>
        </select>
        <input
          type="text"
          :class="[
            'form-control short text-center',
            {'invalid-field': isInvalidValue(form.border.width)}
          ]"
          v-model="form.border.width"
          data-toggle="popover"
          data-trigger="hover"
          data-placement="top"
          :data-content="
            isInvalidValue(form.border.width)
              ? invalidValueMessage({units: 1})
              : ''
          "
        />
        <ColorPicker
          class="color-picker"
          v-bind:title="$t('synoptic.background_color')"
          v-model="form['background-color']"
          icon="background"
          style="display: inline-block; vertical-align: middle;margin-left:20px;"
        />
      </div>
    </div>
  </section>
</template>

<script>
import {isEqual} from "lodash";
import ColorPicker from "@/components/editor/color-picker";
import ToolTip from "@/components/tooltip.vue";
export default {
  name: "StrokeAndFillInput",
  components: {
    ColorPicker,
    ToolTip
  },
  props: {
    value: {
      type: Object,
      default: null,
      required: true
    },
    label: {
      type: String,
      default: "titles.stroke_and_fill",
      required: false
    },
    hint: {
      type: String,
      default: "hints.stroke_and_fill",
      required: false
    }
  },
  data() {
    return {
      form: null,
      borderStyles: [
        "solid",
        "dotted",
        "dashed",
        "double",
        "groove",
        "ridge",
        "inset",
        "outset",
        "none"
      ]
    };
  },

  computed: {
    payload: {
      set(value) {
        let form = {
          "background-color": value["background-color"].replace(/\s/g, "")
        };
        let splitted = value.border.split(" ");
        if (splitted.length > 2) {
          form.border = {
            width: splitted.splice(0, 1)[0],
            style: splitted.splice(0, 1)[0],
            color: splitted.join("").replace(/\s/g, "")
          };
        }
        this.$set(this, "form", form);
      },
      get() {
        return (
          this.form && {
            "background-color": this.form["background-color"].replace(
              /\s/g,
              ""
            ),
            border: `${this.form.border.width} ${
              this.form.border.style
            } ${this.form.border.color.replace(/\s/g, "")}`
          }
        );
      }
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(n) {
        if (n && !isEqual(n, this.payload)) {
          this.payload = n;
        }
      }
    },
    payload: {
      deep: true,
      handler(n) {
        if (n) {
          if (!isEqual(n, this.value)) {
            this.$emit("input", {...n});
          }
        }
      }
    }
  },
  methods: {
    isInvalidValue(value) {
      return !value?.match?.(/\d(px|%)/);
    },
    invalidValueMessage({units = 2} = {}) {
      return this.$tc("invalid_css_value", units);
    }
  },
  created() {}
};
</script>

<style scoped>
.form-inline {
  margin: 0.5rem 0;
}

.color-picker {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
}

.short {
  max-width: 19%;
}

.medium {
  max-width: 37%;
}

.invalid-field {
  box-shadow: 0 0 2px 1px rgba(216, 32, 32, 0.68);
  border-color: transparent;
}
</style>
