<template>
  <div class="property-wrapper">
    <template v-if="controls.length == 1">
      <ControlBaseProperties v-model="control" />
      <ControlSpecificPanel :control="control">
        <TogglePanel
          :title="$t('titles.shape')"
          :hint="$t('hints.shapes')"
          accordion="shape"
        >
          <section class="form-group form-group-sm">
            <div class="gallery-grid">
              <template v-for="(item, ix) in shapeList">
                <div
                  :key="ix"
                  class="btn btn-sm"
                  :title="itemTitle(item)"
                  :class="item.id == shapeId ? 'text-primary active' : ''"
                  @click="onShapeClick($event, item.id)"
                >
                  <i :style="item.icon"></i>
                </div>
              </template>
            </div>
          </section>
        </TogglePanel>
        <TogglePanel
          title="titles.stroke_and_fill"
          hint="hints.stroke_and_fill"
          accordion="shape"
        >
          <StrokeAndFillInput v-model="strokeAndFillValue" label="" />
          <section class="form-group form-group-sm">
            <label for="ctrlOpacity">
              {{ $t("synoptic.opacity") }} ({{ opacityValue }})
              <ToolTip :title="$t('hints.opacity')" />
            </label>
            <input
              id="ctrlOpacity"
              type="range"
              min="0"
              max="1"
              step="0.1"
              v-model="opacityValue"
            />
          </section>
        </TogglePanel>
        <TogglePanel
          title="synoptic.shadow"
          hint="hints.shadow"
          accordion="shape"
        >
          <BoxShadowInput
            v-model="shadowBoxValue"
            :allowInset="!hasSVGContent"
            label="show"
            hint="hints.enable_shadow"
          />
        </TogglePanel>
      </ControlSpecificPanel>
    </template>
  </div>
</template>

<script>
import {isEqual} from "lodash";
import Shapes from "@/assets/dashboard/shapes.json";
import Controls from "@/assets/dashboard/controls.json";
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";
import BoxShadowInput from "@/components/synoptic/property-editor/controls/box-shadow-input.vue";
import StrokeAndFillInput from "@/components/synoptic/property-editor/controls/stroke-and-fill-input.vue";
import ToolTip from "@/components/tooltip.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import messages from "@/i18n/image-editor.js";

const min_shape_size = 40;

const synopticComponentTemplate = () => {
  return structuredClone(
    Controls.find(({name}) => name == "SynopticShape")?.template
      ?.synopticComponent
  );
};

const getMaxSize = (item) => {
  if (!item?.synopticComponent?.clientRect) return -1;
  let size =
    item.synopticComponent.clientRect.width >
    item.synopticComponent.clientRect.height
      ? item.synopticComponent.clientRect.width
      : item.synopticComponent.clientRect.height;
  return (size < min_shape_size && min_shape_size) || size;
};

const getShape = (shapeId) => {
  return shapeId && Shapes.find(({id}) => id == shapeId);
};

const shapeParser = (item) => {
  const shape = getShape(item?.synopticComponent?.shape_id);
  if (!shape) return;
  let prv = {...item.synopticComponent.shape};
  item.synopticComponent.shape = structuredClone(shape);
  if (shape.svg_content) return;
  // html based shapes
  switch (shape.id) {
    case "square": {
      const tpl = synopticComponentTemplate();
      if (shape.id != prv.id) {
        let size = getMaxSize(item);
        if (size < 0) return;
        item.synopticComponent.clientRect.width = size;
        item.synopticComponent.clientRect.height = size;
      }
      item.synopticComponent.style["border-radius"] =
        tpl.style["border-radius"];
      break;
    }
    case "rect": {
      const tpl = synopticComponentTemplate();
      if (shape.id != prv.id) {
        let size = getMaxSize(item);
        if (size < 0) return;
        item.synopticComponent.clientRect.width = size;
        item.synopticComponent.clientRect.height = size * 0.6;
      }
      item.synopticComponent.style["border-radius"] =
        tpl.style["border-radius"];
      break;
    }
    case "circle": {
      let size = getMaxSize(item);
      if (size < 0) return;
      item.synopticComponent.clientRect.width = size;
      item.synopticComponent.clientRect.height = size;
      item.synopticComponent.style["border-radius"] = "50%";
      break;
    }
    case "ellipse": {
      let size = getMaxSize(item);
      if (size < 0) return;
      item.synopticComponent.clientRect.width = size;
      item.synopticComponent.clientRect.height = size * 0.7;
      item.synopticComponent.style["border-radius"] = "50%";
      break;
    }
    case "rounded_square": {
      if (shape.id != prv.id) {
        let size = getMaxSize(item);
        if (size < 0) return;
        item.synopticComponent.clientRect.width = size;
        item.synopticComponent.clientRect.height = size;
      }
      item.synopticComponent.style["border-radius"] = "12px";
      break;
    }
    case "rounded_rect": {
      if (shape.id != prv.id) {
        let size = getMaxSize(item);
        if (size < 0) return;
        item.synopticComponent.clientRect.width = size;
        item.synopticComponent.clientRect.height = size * 0.6;
      }
      item.synopticComponent.style["border-radius"] = "12px";
      break;
    }
  }
};

export default {
  name: "Shape",
  components: {
    ControlBaseProperties,
    ControlSpecificPanel,
    BoxShadowInput,
    ToolTip,
    StrokeAndFillInput,
    TogglePanel
  },
  i18n: {messages},
  extends: BaseControl,
  data() {
    return {
      control: {}
    };
  },
  computed: {
    shapeList() {
      return [...Shapes];
    },
    shapeId: {
      set(value) {
        this.control.synopticComponent.shape_id = value;
        shapeParser(this.control);
      },
      get() {
        return this?.control?.synopticComponent?.shape_id || "";
      }
    },
    opacityValue: {
      set(value) {
        let style = this?.control?.synopticComponent?.style || {};
        style = {...style, opacity: Number(value)};
        this.$set(this.control.synopticComponent, "style", style);
      },
      get() {
        return this?.control?.synopticComponent?.style?.opacity ?? 1;
      }
    },
    shadowBoxValue: {
      set(value) {
        let style = this?.control?.synopticComponent?.style || {};
        style = {...style, "box-shadow": value};
        this.$set(this.control.synopticComponent, "style", style);
      },
      get() {
        return this?.control?.synopticComponent?.style?.["box-shadow"] ?? "";
      }
    },
    strokeAndFillValue: {
      set(value) {
        let style = this?.control?.synopticComponent?.style || {};
        style = {...style, ...value};
        this.$set(this.control.synopticComponent, "style", style);
      },
      get() {
        return {
          "background-color":
            this?.control?.synopticComponent?.style?.["background-color"] ?? "",
          border: this?.control?.synopticComponent?.style?.["border"] ?? ""
        };
      }
    },
    hasSVGContent() {
      return this?.control?.synopticComponent?.shape?.svg_content || false;
    }
  },
  watch: {
    control: {
      deep: true,
      handler(val) {
        this.updateControl({
          id: val.id,
          control: {
            ...val,
            synopticComponent: {
              ...val.synopticComponent,
              clientRect: {
                ...val.synopticComponent.clientRect
              },
              shape: val.synopticComponent.shape
            }
          },
          noMerge: true
        });
      }
    },
    controls: {
      deep: true,
      handler(val) {
        let n = JSON.parse(JSON.stringify(val[0]));
        let o = JSON.parse(JSON.stringify(this.control));
        if (!isEqual(n, o)) {
          this.control = JSON.parse(JSON.stringify(n));
        }
      }
    }
  },
  methods: {
    updateControl(payload) {
      this.$store.dispatch("synoptic/updateControl", payload);
    },
    onShapeClick($e, shapeId) {
      if ($e.ctrlKey) {
        let control = null;
        control = structuredClone(this.control);
        delete control.id;
        if ($e.shiftKey) {
          let rect = control.synopticComponent.clientRect;
          control.synopticComponent = synopticComponentTemplate();
          control.synopticComponent.shape_id = shapeId;
          control.synopticComponent.clientRect = rect;
          shapeParser(control);
        } else if (shapeId != control.synopticComponent.shape_id) {
          control.synopticComponent.shape_id = shapeId;
          shapeParser(control);
        }
        control.synopticComponent.clientRect.top += 10;
        control.synopticComponent.clientRect.left += 10;
        this.$store.dispatch("synoptic/addControl", control).then(() => {
          document
            .querySelector(".keyboard-container")
            .focus({preventScroll: true});
        });
        return;
      }
      this.shapeId = shapeId;
    },
    itemTitle(item) {
      return `${this.$t(item.title)}\n${this.$t("hints.shape_handle")}`;
    }
  },
  mounted() {
    this.control = JSON.parse(JSON.stringify(this.controls[0]));
  }
};
</script>
<style scoped>
label {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-auto-flow: row;
  overflow: hidden;
  gap: 2px;
  padding: 5px;
}

.gallery-grid > div.btn {
}

.gallery-grid > div.btn.active {
  -webkit-box-shadow: inset 0 0px 6px 0px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0px 6px 0px rgba(0, 0, 0, 0.1);
}

.gallery-grid > div.btn > i {
  width: 18px;
  height: 18px;
  background-color: currentColor;
  position: relative;
  z-index: var(--layer-above);
  display: inline-block;
}

.skin-dark .shape-icon > i {
  filter: grayscale(0.9) brightness(200%);
}

.skin-dark .shape-icon:hover > i {
  filter: grayscale(1) brightness(300%);
}

input[type="range"]:hover {
  cursor: pointer;
}
</style>
