<template>
  <section class="">
    <div class="row">
      <div class="col-xs-5 form-group form-group-sm" style="padding-right;0">
        <label
          for="ctrlBoxShadow"
          class="checkbox-inline"
          style="whitelabel: nowrap"
        >
          <input type="checkbox" v-model="enabled" id="ctrlBoxShadow" />
          {{ $t(label) }} <ToolTip :title="$t(hint)" />
        </label>
      </div>
      <div
        class="col-xs-7 form-group form-group-sm"
        v-if="enabled && allowInset"
      >
        <label for="ctrlBoxShadowInset" class="checkbox-inline">
          <input
            type="checkbox"
            v-model="internalValue"
            id="ctrlBoxShadowInset"
          />
          {{ $tc("titles.internal", 2) }}
          <ToolTip :title="$t('hints.inner_shadow')" />
        </label>
      </div>
    </div>
    <template v-if="enabled">
      <div class="form-group form-group-sm">
        <div style="display:inline-block;margin-right:5px;">
          <label for="">{{ $tc("color", 1) }}</label>
        </div>
        <div style="display:inline-block">
          <ColorPicker
            :title="$t('hints.shadow_color')"
            class="color-picker"
            v-model="colorValue"
            icon="fa fa-paint-brush"
          />
        </div>
      </div>
      <InlineDimensionForm
        v-model="dimensionValue"
        :lockables="[0, 1, 2, 3]"
        :labels="['left', 'top', 'titles.blur', 'titles.length']"
        hint="hints.shadow_dimension"
      />
    </template>
  </section>
</template>

<script>
import ColorPicker from "@/components/editor/color-picker";
import InlineDimensionForm from "./inline-dimension-form.vue";
import ToolTip from "@/components/tooltip.vue";
const dftValue = "6px 6px 15px 0px rgba(0,0,0,1)";
export default {
  name: "BoxShadowInput",
  components: {
    ColorPicker,
    InlineDimensionForm,
    ToolTip
  },
  props: {
    value: {
      type: String,
      default: "none" // 0px 0px 0px 0px rgba(0,0,0,0) inset
    },
    allowInset: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "synoptic.shadow",
      required: false
    },
    hint: {
      type: String,
      default: "hints.shadow",
      required: false
    }
  },
  computed: {
    enabled: {
      set(value) {
        if (value) {
          this.$emit("input", dftValue);
        } else {
          this.$emit("input", "none");
        }
      },
      get() {
        return this.value != "none";
      }
    },
    values: {
      set(value) {
        if (!this.enabled) return;
        this.$emit("input", value.join(" "));
      },
      get() {
        return this.value.split(" ");
      }
    },
    colorValue: {
      set(value) {
        let lst = [...this.values];
        lst[4] = value.replace(/\s/g, "");
        this.values = lst;
      },
      get() {
        return this.values[4];
      }
    },
    dimensionValue: {
      set(value) {
        let lst = [...this.values];
        lst.splice(0, 4, ...value.map((i) => `${i}px`));
        this.values = lst;
      },
      get() {
        let v = [...this.values];
        return v.slice(0, 4).map((i) => i.replace("px", ""));
      }
    },
    internalValue: {
      set(value) {
        let lst = [...this.values];
        if (value) {
          lst[5] = "inset";
        } else {
          delete lst[5];
        }
        this.values = lst;
      },
      get() {
        return this.values[5] == "inset";
      }
    }
  }
};
</script>

<style scoped>
.checkbox-inline {
  font-weight: 700;
}
.color-picker {
  padding: 0;
}
</style>
