// prettier-ignore
export default {
  pt: {
    "titles":{
      'image': 'Imagem|Imagens',
      'object': 'Objeto|Objetos',
      'gallery': 'Galeria',
      'flip': 'Virar',
      "opacity":"Opacidade",
      'thickess_and_colors':"Espessura e cores",
      "shape":"Forma"
    },
    "hints":{
      'flip': 'Vira os objetos verticalmente ou horizontalmente'
    },
    shapes:{
      square:"Quadrado",
      retangle:"Retângulo",
      circle:"Círculo",
      ellipse:"Elípse",
      triangle:"Triângulo",
      rhombus:"Losângo",
      trapezoid:"Trapézio",
      pentagon:"Pentágono",
      hexagon:"Hexágono",
      polygon:"Polígono",
      arrow:"Seta",
      line:"Linha",
      star:"Estrela",
      rounded_square:"Quadrado arredondado",
      rounded_rect:"Retângulo arredondado",
    }
  },
  en: {
    titles:{
      'image': 'Image|Images',
      'object': 'Object|Objects',
      'gallery': 'Gallery',
      'flip': 'Virar',
      "opacity":"Opacity",
      'thickess_and_colors':"Thickness and colors",
      "shape":"Shape"
    },
    hints:{
      'flip': 'Flips objects vertically or horizontally',
    },
    shapes:{
      square:"Square",
      retangle:"Rectangle",
      circle:"Circle",
      ellipse:"Ellipse",
      triangle:"Triangle",
      rhombus:"Rhombus",
      trapezoid:"Trapezoid",
      pentagon:"Pentagon",
      hexagon:"Hexagon",
      polygon:"Polygon",
      arrow:"Arrow",
      line:"Line",
      star:"Star",
      rounded_square:"Rounded square",
      rounded_rect:"Rounded rectangle",
    }
  },
  
}
